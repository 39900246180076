import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

export const QuizPaper = ({ id, getByID, setCode, setAssign, code, timer, completed }) => {
  const [paper, setPaper] = useState();
  const [time, setTime] = useState(false);
  useEffect(() => {
    setInterval(() => {
      QuizRequest(id, setPaper, getByID);
    }, 1000);

    if (timer > 0) {
      setTimeout(() => {
        setTime((prev) => true);
      }, timer);
    } else {
      setTime((prev) => true);
    }
  }, []);
  return (
    <>
      {completed == 'Submitted' ? (
        <span className="text-success">{completed}</span>
      ) : time == true ? (
        paper ? (
          <Link
            onClick={() => {
              setCode((prev) => code);
              setAssign((prev) => id);
            }}
            data-bs-toggle="modal"
            data-bs-target="#mcqModal"
            className="text-link"
          >
            Start
          </Link>
        ) : (
          <span className="text-danger">Missing</span>
        )
      ) : (
        <span className="text-warning">Ready</span>
      )}
    </>
  );
};

const QuizRequest = (id, setPaper, getByID) => {
  getByID(id).then((data) => {
    if (data) {
      if (data.exam.type == 'MCQ') {
        const questions = data.exam.paper;
        setPaper((prev) => questions);
      }
    }
  });
};
