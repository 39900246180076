import { Request } from './request';

export const SubmitPDFRequest = (data, navigate, setError, setSuccess, indexDb, setShow, btn, deleteRecord) => {
  Request.post('/file/openended', data)
    .then((res) => {
      setShow((prev) => false);
      setSuccess((prev) => 'Examination submitted');
      setError((prev) => '');
      deleteRecord(data.assignid);
      btn.examSubmitted.click();
    })
    .catch((err) => {
      setShow((prev) => false);
      if (err.response) {
        if (err.response.status == 401) {
          navigate('/login');
        }
        if (err.response.status == 422) {
          setSuccess((prev) => '');
          setError((prev) => err.response.data.message);
          btn.examFailed.click();
        }
      } else {
        setError((prev) => 'Unable to submit examination, check your connection');
      }
    });
};
