export const ButtonsModal = () => {
  return (
    <>
      <button type="button" className="d-none" data-bs-toggle="modal" id="savedClick" data-bs-target="#examSaved"></button>
      <button type="button" className="d-none" data-bs-toggle="modal" id="submittedClick" data-bs-target="#examSubmitted"></button>
      <button type="button" className="d-none" data-bs-toggle="modal" id="failedClick" data-bs-target="#examFailed"></button>
      <button type="button" className="d-none" data-bs-toggle="modal" id="syncClick" data-bs-target="#syncExam"></button>
    </>
  );
};
