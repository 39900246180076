import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const OpenHandedModal = ({ code, assignid }) => {
  const [error, setError] = useState();
  const navigate = useNavigate();

  const startText = () => {
    const declare = document.getElementById('openDeclare');
    if (declare.checked) {
      setError((prev) => '');
      navigate('/submit/text', { state: { code: code, assignid: assignid } });
    } else {
      setError((prev) => 'Fill in declartion');
    }
  };

  const startPdf = () => {
    const declare = document.getElementById('openDeclare');
    if (declare.checked) {
      setError((prev) => '');
      navigate('/submit/pdf', { state: { code: code, assignid: assignid } });
    } else {
      setError((prev) => 'Fill in declartion');
    }
  };

  return (
    <div className="modal fade" id="openHandedModal" tabIndex={-1} aria-labelledby="openHandedModal" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {code} Examination
            </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="input-group mb-3">
              <label htmlFor="declare">
                <input type="checkbox" id="openDeclare" className="form-check-input me-2" />
                By starting this exam, I confirm my commitment to uphold academic integrity and abide by all exam regulations. I understand that for written assessments, I will receive an SMS code on my registered phone number to unlock the question paper, while quizzes do not require a code and can be accessed directly on the platform.
                <li>I agree not to engage in cheating, use unauthorized resources, or share exam content in any form. I understand that switching devices, using multiple browsers, or attempting to bypass security measures is prohibited and may lead to disqualification or disciplinary action. </li>
                <li>I accept responsibility for any technical preparations required and I acknowledge that my actions may be monitored, and I am solely accountable for maintaining exam integrity. I accept responsibility for my conduct during this exam.</li>
              </label>
            </div>

            {error && (
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button onClick={() => startText()} type="button" data-bs-dismiss="modal" className="btn btn-primary">
              Text Submit
            </button>

            <button onClick={() => startPdf()} type="button" data-bs-dismiss="modal" className="btn btn-primary">
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
