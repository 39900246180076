import { Request } from './request';

export const OTPRequest = (data, setError, setSuccess, navigate, setShow) => {
  Request.post('/generate/otp', data)
    .then((res) => {
      setShow((prev) => false);
      setError((prev) => '');
      setSuccess((prev) => 'OTP has been sent to your email');
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status == 422) {
          setError((prev) => err.response.data.message);
          setSuccess((prev) => '');
        }
        if (err.response.status == 401) {
          navigate('/');
        }
      } else {
        setError((prev) => 'Unable send otp,Contact web master');
        setSuccess((prev) => '');
      }
      setShow((prev) => false);
    });
};
