import { Request } from './request';
import Cookies from 'universal-cookie';

export const LogoutRequest = (navigate) => {
  const cookie = new Cookies();

  Request.post('/logout')
    .then((res) => {
      cookie.remove('_xusr');
      window.location.href = '/login';
      //navigate('/login');
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status == 401) {
          window.location.href = '/login';
          //navigate('/login');
        }
      }
      //navigate('/login');
    });
};
