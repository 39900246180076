export const ToogleQuestion = ({ questions, setQIndex }) => {
  const change = (e) => {
    const selectElement = e.target;
    const selectedIndex = selectElement.selectedIndex;
    setQIndex((prev) => selectedIndex);
  };
  return (
    <select className="my-2 rounded bg-white" onChange={(e) => change(e)} id="question">
      {questions &&
        questions.length > 0 &&
        questions.map((val, index) => {
          return (
            <option key={index} value={index}>
              Question {index + 1}
            </option>
          );
        })}
    </select>
  );
};
