import { Request } from './request';

export const QuizRequest = (navigate, setResults, setLoading, indexDb) => {
  Request.post('/quiz/results')
    .then(async (res) => {
      setResults((prev) => res.data);
      setLoading((prev) => false);
      indexDb.getByID('quiz').then((data) => {
        if (data) {
          indexDb.deleteRecord('quiz').then((err) => {
            indexDb.add({ id: 'quiz', quiz: res.data });
          });
        } else {
          indexDb.add({ id: 'quiz', quiz: res.data });
        }
      });
    })
    .catch((err) => {
      if (!navigator.onLine) {
        indexDb.getByID('quiz').then((data) => {
          if (data) {
            setResults((prev) => data.quiz);
          }
        });
        setLoading((prev) => false);
      } else {
        if (err.response) {
          if (err.response.status == 401) {
            navigate('/login');
          }
        }
        setResults((prev) => []);
        setLoading((prev) => false);
      }
    });
};

export const OpenHandedRequest = (navigate, setOpenHanded, setLoading, indexDb) => {
  Request.post('/assign/results')
    .then((res) => {
      setOpenHanded((prev) => res.data);
      setLoading((prev) => false);
      indexDb.getByID('assign').then((data) => {
        if (data) {
          indexDb.deleteRecord('assign').then((err) => {
            indexDb.add({ id: 'assign', assign: res.data });
          });
        } else {
          indexDb.add({ id: 'assign', assign: res.data });
        }
      });
    })
    .catch((err) => {
      if (!navigator.onLine) {
        indexDb.getByID('assign').then((data) => {
          if (data) {
            setOpenHanded((prev) => data.assign);
          }
        });
        setLoading((prev) => false);
      } else {
        if (err.response) {
          if (err.response.status == 401) {
            navigate('/login');
          }
        }
        setOpenHanded((prev) => []);
        setLoading((prev) => false);
      }
    });
};
