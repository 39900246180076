export const Indicators = ({ submissions, modules, past }) => {
  return (
    <div className="row mx-1 mt-4 gap-2">
      <div className="col-12 col-md rounded shadow-sm bg-white">
        <div className="row py-2 d-flex align-items-center justify-content-center">
          <div style={{ width: 50, height: 50, border: 'solid 6px red', borderRadius: 100 }} className="shadow-sm border-danger m-2 d-flex justify-content-center align-items-center">
            <span className="bold text-danger">{modules ? modules.length : 0}</span>
          </div>

          <div className="col">
            <p className="bold align-self-center mt-2">MODULES</p>
          </div>
        </div>
      </div>

      <div className="col-12 col-md rounded shadow-sm bg-white">
        <div className="row py-2 d-flex align-items-center justify-content-center">
          <div style={{ width: 50, height: 50, border: 'solid 6px red', borderRadius: 100 }} className="shadow-sm border-primary m-2 d-flex justify-content-center align-items-center">
            <span className="bold text-primary">{submissions ? submissions.length : 0}</span>
          </div>

          <div className="col">
            <p className="bold align-self-center mt-2">SUBMISSIONS</p>
          </div>
        </div>
      </div>

      <div className="col-12 col-md rounded shadow-sm bg-white">
        <div className="row py-2 d-flex align-items-center justify-content-center">
          <div style={{ width: 50, height: 50, border: 'solid 6px red', borderRadius: 100 }} className="shadow-sm border-warning m-2 d-flex justify-content-center align-items-center">
            <span className="bold text-warning">{past ? past.length : 0}</span>
          </div>

          <div className="col">
            <p className="bold align-self-center mt-2">PAST EXAMS</p>
          </div>
        </div>
      </div>
    </div>
  );
};
