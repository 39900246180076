import { DesktopNav } from '../components/moodle/common/desktopNav';
import { Header } from '../components/moodle/common/commonHeader';
import { RightNav } from '../components/moodle/common/rightNav';
import { useEffect, useState } from 'react';
import { RequestLoader } from '../components/common/requestLoader';
import { UpdateCellRequest } from '../requests/moodle/updateprofileRequest';
import { UpdatePictureRequest } from '../requests/moodle/updateprofileRequest';
import { useIndexedDB } from 'react-indexed-db-hook';
import { FaUser } from 'react-icons/fa';

export const SettingsPage = () => {
  window.document.title = 'Settings';

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [show, setShow] = useState();
  const [temp, setTemp] = useState();
  const [picture, setPicture] = useState();
  const [user, setUser] = useState();

  const { getByID } = useIndexedDB('user');

  useEffect(() => {
    getByID('picture').then((data) => {
      if (data) {
        const profile = URL.createObjectURL(data.picture);
        setPicture((prev) => profile);
      }
    });
    getByID('user').then((data) => {
      if (data) {
        setUser((prev) => data.user);
      }
    });
  }, []);

  const UpdateCellNumber = () => {
    const cellNumber = document.getElementById('cellNumber').value;

    const data = {
      cellNumber: cellNumber,
    };

    if (cellNumber) {
      setShow((prev) => true);
      UpdateCellRequest(data, setError, setSuccess, setShow);
    } else {
      setError((prev) => 'Enter cell number');
    }
  };

  const UpdatePicture = () => {
    const file = document.getElementById('picture');
    const data = new FormData();
    data.append('file', file.files[0]);

    if (file.files[0]) {
      const fileUrl = URL.createObjectURL(file.files[0]);
      setTemp((prev) => fileUrl);
      setShow((prev) => true);
      UpdatePictureRequest(data, setError, setSuccess, setShow);
    } else {
      setError((prev) => 'Please upload profile picture');
      window.scroll(0, document.body.scrollHeight);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {(show || !user) && <RequestLoader />}
        <div className="col-2 d-none d-lg-block">
          <DesktopNav />
        </div>

        <div className="col-lg-10 col mb-4">
          <Header />

          <div className="row">
            <div className="col-lg-9 col">
              <p className="bold mt-2">Settings</p>

              <div className="col my-3">
                <input hidden id="picture" type="file" onChange={() => UpdatePicture()} accept="image/*"></input>
                {picture && !temp && <img style={{ borderRadius: 100, width: 80, height: 80, cursor: 'pointer' }} src={picture} onClick={() => document.getElementById('picture').click()} />}
                {!picture && !temp && <FaUser style={{ cursor: 'pointer' }} onClick={() => document.getElementById('picture').click()} size={60} />}
                {temp && <img style={{ borderRadius: 100, width: 100, height: 100, cursor: 'pointer' }} src={temp} onClick={() => document.getElementById('picture').click()} />}
              </div>

              <div className="col">
                {user && (
                  <>
                    <div className="form-group mb-2">
                      <label htmlFor="email">Email Address</label>
                      <input type="text" defaultValue={user.email} readOnly className="form-control"></input>
                    </div>
                    <div className="form-group mb-2">
                      <label htmlFor="email">Student Number</label>
                      <input type="text" readOnly defaultValue={user.studentNo} className="form-control"></input>
                    </div>
                    <div className="form-group mb-2">
                      <label htmlFor="email">Name</label>
                      <input type="text" readOnly defaultValue={user.name} className="form-control"></input>
                    </div>
                    <div className="form-group mb-2">
                      <label htmlFor="email">Surname</label>
                      <input type="text" readOnly defaultValue={user.surname} className="form-control"></input>
                    </div>
                    <div className="form-group mb-2">
                      <label htmlFor="email">ID Number</label>
                      <input type="text" readOnly defaultValue={user.idNumber} className="form-control"></input>
                    </div>
                    <div className="form-group mb-2">
                      <label htmlFor="email">School</label>
                      <input type="text" readOnly defaultValue={user.schoolName} className="form-control"></input>
                    </div>
                    <div className="form-group mb-2">
                      <label htmlFor="email">Cell number</label>
                      <input type="text" id="cellNumber" defaultValue={user.cellNumber.substring(2)} className="form-control"></input>
                    </div>
                  </>
                )}

                <div>
                  <button onClick={() => UpdateCellNumber()} className="btn btn-primary text-white my-2">
                    Update
                  </button>
                </div>
                {error && (
                  <div className="alert alert-danger my-3" role="alert">
                    {error}
                  </div>
                )}

                {success && (
                  <div className="alert alert-success my-3" role="alert">
                    {success}
                  </div>
                )}
              </div>
            </div>

            <div className="col-3 d-none d-lg-block">
              <RightNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
