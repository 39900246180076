import { Request } from './request';

export const RegisterRequest = (data, setError, setShow, navigate) => {
  Request.post('/register', data)
    .then((res) => {
      setShow((prev) => false);
      setError((prev) => '');
      navigate('/registered');
    })
    .catch((err) => {
      if (err.response.status == 422) {
        setError((prev) => err.response.data.message);
      } else {
        setError((prev) => 'Unable register,Contact web master');
      }
      setShow((prev) => false);
    });
};
