import { Link } from 'react-router-dom';
import { IoMdSettings } from 'react-icons/io';
import { IoLockClosed } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { UserRequest } from '../../../requests/moodle/userRequest';
import { LogoutRequest } from '../../../requests/moodle/logoutRequest';
import { useIndexedDB } from 'react-indexed-db-hook';
import { MobileNav } from './mobileNav';

export const Header = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const { add, getByID, deleteRecord } = useIndexedDB('user');

  const Logout = () => {
    LogoutRequest(navigate);
  };

  useEffect(() => {
    UserRequest(navigate, setUser, { add, deleteRecord, getByID }, false);
  }, []);

  return (
    <div className="row d-flex align-items-center mt-lg-2">
      <div className="col-12 d-lg-block d-none">
        <div className="row d-flex align-items-center">
          <div className="col">{user && <h3 className="bold">Welcome, {user.name}</h3>}</div>
          <div className="col d-flex justify-content-end">
            <div className="row">
              <div className="col">
                <Link to="/profile" className="text-dark">
                  <IoMdSettings size={20} />
                </Link>
              </div>
              <div className="col">
                <Link onClick={() => Logout()} className="text-dark">
                  <IoLockClosed size={20} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 d-lg-none d-block bg-dark">{user && <MobileNav name={user.name} />}</div>
    </div>
  );
};
