import { initDB } from 'react-indexed-db-hook';

const dbName = 'AnnConnect';
const dbVersion = 2;

const AnnConIndexed = {
  name: dbName,
  version: dbVersion,
  objectStoresMeta: [
    {
      store: 'papers',
      storeConfig: { keyPath: 'id' },
      storeSchema: [{ name: 'paper', keypath: 'paper', options: { unique: false } }],
    },
    {
      store: 'results',
      storeConfig: { keyPath: 'id' },
      storeSchema: [{ name: 'results', keypath: 'results', options: { unique: false } }],
    },
    {
      store: 'modules',
      storeConfig: { keyPath: 'id' },
      storeSchema: [{ name: 'modules', keypath: 'modules', options: { unique: false } }],
    },
    {
      store: 'user',
      storeConfig: { keyPath: 'id' },
      storeSchema: [{ name: 'user', keypath: 'user', options: { unique: false } }],
    },
    {
      store: 'assessments',
      storeConfig: { keyPath: 'id' },
      storeSchema: [{ name: 'assessments', keypath: 'assessments', options: { unique: false } }],
    },
    {
      store: 'dashboard',
      storeConfig: { keyPath: 'id' },
      storeSchema: [{ name: 'dashboard', keypath: 'dashboard', options: { unique: false } }],
    },
    ,
    {
      store: 'exams',
      storeConfig: { keyPath: 'id' },
      storeSchema: [{ name: 'exams', keypath: 'exams', options: { unique: false } }],
    },
    ,
    {
      store: 'mymodules',
      storeConfig: { keyPath: 'id' },
      storeSchema: [{ name: 'modules', keypath: 'modules', options: { unique: false } }],
    },
  ],
};

export const AnnConnectDb = () => {
  initDB(AnnConIndexed);
};
