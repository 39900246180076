import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VerifyRequest } from '../requests/moodle/verifyRequest';
import { OTPRequest } from '../requests/moodle/otpRequest';
import { useIndexedDB } from 'react-indexed-db-hook';
import { RequestLoader } from '../components/common/requestLoader';

export const VerifyPage = () => {
  window.document.title = 'Verify your account';
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [show, setShow] = useState();
  const [email, setEmail] = useState();
  const { getByID } = useIndexedDB('user');

  const navigate = useNavigate();

  const Verify = () => {
    const otp = document.getElementById('otp');
    const data = { otp: otp.value };
    if (otp.value) {
      setShow((prev) => true);
      VerifyRequest(data, setError, setSuccess, navigate, setShow);
    } else {
      setError((prev) => 'Please enter the OTP');
    }
  };

  const Resend = () => {
    const data = { email: email };
    OTPRequest(data, setError, setSuccess, navigate, setShow);
  };

  useEffect(() => {
    getByID('user').then((data) => {
      if (data) {
        setEmail((prev) => data.email);
      } else {
        navigate('/');
      }
    });
  }, []);

  return (
    <div className="container-fluid signupContainer d-flex d-md-flex justify-content-center align-items-center">
      {show && <RequestLoader />}
      <div className="col-md-10 mx-auto col-12">
        <div className="row">
          <div className="mx-auto col-11 col-md-7 col-lg-5 bg-white signupRound mt-4">
            <div className="form-group mb-2 mx-2 mx-md-3">
              <h1 className="bold mb-md-4 mt-4">Verify your account</h1>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">One time password (OTP)</label>
              <input type="text" id="otp" placeholder="OTP" className="form-control"></input>
            </div>

            <div className="mb-3 mx-md-3 mt-3 mx-2">
              <button onClick={() => Verify()} style={{ background: '#0A4F92' }} className="form-control btn text-white ">
                Submit
              </button>
              {error && (
                <div className="alert alert-danger my-3" role="alert">
                  {error}
                </div>
              )}
              {success && (
                <div className="alert alert-success my-3" role="alert">
                  {success}
                </div>
              )}
            </div>

            <div className="form-group mb-5 mx-md-3 mx-2">
              <p className="m-0">
                Didn’t get code?{' '}
                <span onClick={() => Resend()} style={{ cursor: 'pointer' }} className="text-primary">
                  Resend
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
