import { Request } from './request';

export const UserRequest = (navigate, setUser, indexDb, save) => {
  Request.get('/profile')
    .then((res) => {
      setUser((prev) => res.data);
      if (res.data.verified == false) {
        navigate('/verify');
      }
      if (save) {
        indexDb.getByID('user').then((data) => {
          if (data) {
            indexDb.deleteRecord('user').then((deleted) => {
              if (deleted) {
                indexDb.add({ id: 'user', user: res.data });
              }
            });
          } else {
            indexDb.add({ id: 'user', user: res.data });
          }
        });
      }

      Profile(res.data, indexDb);
    })
    .catch((err) => {
      if (!navigator.onLine) {
        indexDb.getByID('user').then((data) => {
          if (data) {
            setUser((prev) => data.user);
          } else {
            setUser((prev) => []);
          }
        });
      } else {
        if (err.response) {
          if (err.response.status == 401) {
            navigate('/login');
          }
        }
      }
    });
};

const Profile = (user, indexDb) => {
  if (user.picture) {
    Request.get(user.picture, { responseType: 'blob' }).then((response) => {
      indexDb.getByID('picture').then((data) => {
        if (data) {
          indexDb.deleteRecord('picture').then((err) => {
            indexDb.add({ id: 'picture', picture: response.data });
          });
        } else {
          indexDb.add({ id: 'picture', picture: response.data });
        }
      });
    });
  }
};
