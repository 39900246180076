export const TypeTrueFalse = ({ question, id, answer }) => {
  return (
    <div className="row">
      <p>
        {id}. {question.question}
      </p>
      <div className="input-group mb-3">
        <label htmlFor="declare">
          {answer == '1' ? <input type="radio" defaultChecked name="truefalse" value="1" className="form-check-input me-2" /> : <input type="radio" name="truefalse" value="1" className="form-check-input me-2" />}
          True
        </label>
      </div>

      <div className="input-group mb-3">
        <label for="declare">
          {answer == '0' ? <input type="radio" defaultChecked name="truefalse" value="0" className="form-check-input me-2" /> : <input type="radio" name="truefalse" value="0" className="form-check-input me-2" />}
          False
        </label>
      </div>
    </div>
  );
};
