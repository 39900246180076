import moment from 'moment';

export const AssessmentComp = ({ inProgress, upcoming, past }) => {
  return (
    <div className="col-12 bg-white rounded bg-white shadow-sm mt-4">
      <div className="mx-4 py-4">
        <table>
          <tbody>
            <tr style={{ borderTop: 'solid 1px #ffff' }}>
              <th>Examination</th>
              <th>Completion</th>
              <th>Status</th>
              <th className="d-none d-lg-flex justify-content-center align-items-center">Type</th>
            </tr>
            {inProgress &&
              inProgress.length > 0 &&
              inProgress.map((val, index) => (
                <tr key={index}>
                  <td>{val.name}</td>
                  <td>{moment(val.close).format('DD MMM Y HH:mm:ss')}</td>
                  <td>
                    <span className="text-success">In Progress</span>
                  </td>
                  <td className="d-none d-lg-flex justify-content-center align-items-center">{val.type == 'MCQ' ? <span className="bg-success text-white rounded p-2">{val.type}</span> : <span className="bg-warning text-white rounded p-2">Open-Ended</span>}</td>
                </tr>
              ))}
            {upcoming &&
              upcoming.length > 0 &&
              upcoming.map((val, index) => (
                <tr key={index}>
                  <td>{val.name}</td>
                  <td>{moment(val.close).format('DD MMM Y HH:mm:ss')}</td>
                  <td>
                    <span className="text-info">Upcoming</span>
                  </td>
                  <td className="d-none d-lg-flex justify-content-center align-items-center">{val.type == 'MCQ' ? <span className="bg-success text-white rounded p-2">{val.type}</span> : <span className="bg-warning text-white rounded p-2">Open-Ended</span>}</td>
                </tr>
              ))}
            {past &&
              past.length > 0 &&
              past.map((val, index) => (
                <tr key={index}>
                  <td>{val.name}</td>
                  <td>{moment(val.close).format('DD MMM Y HH:mm:ss')}</td>
                  <td>
                    <span className="text-danger">Past</span>
                  </td>
                  <td className="d-none d-lg-flex justify-content-center align-items-center">{val.type == 'MCQ' ? <span className="bg-success text-white rounded p-2">{val.type}</span> : <span className="bg-warning text-white rounded p-2">Open-Ended</span>}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
