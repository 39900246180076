import { useState, useEffect } from 'react';
import moment from 'moment';

export const DownloadPaper = ({ id, getByID, timer, completed }) => {
  const [paper, setPaper] = useState();
  const [time, setTime] = useState(false);

  useEffect(() => {
    setInterval(() => {
      PDFMagic(id, setPaper, getByID);
    }, 1000);
    if (timer > 0) {
      setTimeout(() => {
        setTime((prev) => true);
      }, timer);
    } else {
      setTime((prev) => true);
    }
  }, []);
  return (
    <>
      {completed == 'Submitted' ? (
        <span className="text-success">{completed}</span>
      ) : time == true ? (
        paper ? (
          <a href={paper} download>
            Download
          </a>
        ) : (
          <span className="text-danger">Missing</span>
        )
      ) : (
        <span className="text-warning">Ready</span>
      )}
    </>
  );
};

const PDFMagic = (id, setPaper, getByID) => {
  getByID(id).then((data) => {
    if (data) {
      if (data.exam.type == 'Open Handed') {
        if (data.exam.paper) {
          const blob = base64toBlob(data.exam.paper);
          const fileUrl = URL.createObjectURL(blob);
          setPaper((prev) => fileUrl);
        }
      }
    }
  });
};

const base64toBlob = (base64String, contentType = 'application/pdf') => {
  const byteCharacters = atob(base64String);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};
