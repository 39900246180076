import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { RequestLoader } from '../components/common/requestLoader';
import { ForgotRequest } from '../requests/moodle/forgotRequest';
import { ResetRequest } from '../requests/moodle/resetRequest';

export const ResetPage = () => {
  window.document.title = 'Reset Password';

  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState();
  const [show, setShow] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  useEffect(() => {
    if (location.state) {
      if (location.state.email) {
        setEmail((prev) => location.state.email);
      } else {
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  }, []);

  const Reset = () => {
    setShow((prev) => true);
    const otp = document.getElementById('otp');
    const password = document.getElementById('password');
    const password2 = document.getElementById('password_confirmation');

    const data = {
      otp: otp.value,
      email: location.state.email,
      password: password.value,
      password_confirmation: password2.value,
    };

    ResetRequest(data, setError, setSuccess, setShow);
  };

  const Resend = () => {
    setShow((prev) => true);
    const data = { email: location.state.email };
    ForgotRequest(data, setError, navigate, setShow);
  };

  return (
    <div className="container-fluid signupContainer d-flex d-md-flex justify-content-center align-items-center">
      {show && <RequestLoader />}
      <div className="col-md-10 mx-auto col-12">
        <div className="row">
          <div className="mx-auto col-11 col-md-7 col-lg-5 bg-white signupRound">
            <div className="form-group mb-2 mx-2 mx-md-3">
              <h1 className="bold mb-md-4 mt-4">Check your email</h1>
            </div>

            <div className="form-group mx-2 mx-md-3">
              <p className="mb-md-4 mt-4">
                We’ve sent the code to <strong>{email}</strong>
              </p>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">One time password (OTP)</label>
              <input type="text" id="otp" placeholder="OTP" className="form-control"></input>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">New password</label>
              <input type="password" id="password" placeholder="********" className="form-control"></input>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">Confirm password</label>
              <input type="password" id="password_confirmation" placeholder="********" className="form-control"></input>
            </div>

            <div className="mb-3 mx-md-3 mt-3 mx-2">
              <button onClick={() => Reset()} style={{ background: '#0A4F92' }} className="form-control btn text-white ">
                Submit
              </button>
              {error && (
                <div className="alert alert-danger my-3" role="alert">
                  {error}
                </div>
              )}

              {success && (
                <div className="alert alert-success my-3" role="alert">
                  {success}
                </div>
              )}
            </div>

            <div className="form-group mb-5 mx-md-3 mx-2">
              <p className="m-0">
                Didn’t get code?
                <span onClick={() => Resend()} className="text-primary" style={{ cursor: 'pointer' }}>
                  Resend
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
