import { InfinitySpin } from 'react-loader-spinner';

export const RequestLoader = () => {
  return (
    <div
      className="m-0 d-flex justify-content-center align-items-center"
      tabIndex={-1}
      style={{
        width: '100%',
        zIndex: 2,
        minHeight: '100vh',
        height: '100%',
        background: 'rgba(255,255,255,8)',
        left: 0,
        position: 'absolute',
      }}
    >
      <div className="d-none d-md-block">
        <InfinitySpin width="100" color="#0A4F92" />
      </div>
      <div className="d-block d-md-none">
        <InfinitySpin width="80" color="#0A4F92" />
      </div>
    </div>
  );
};
