import { createBrowserRouter } from "react-router-dom";
import { LoginPage } from "../views/LoginPage";
import { RegisterPage } from "../views/RegisterPage";
import { ForgotPage } from "../views/ForgotPage";
import { ResetPage } from "../views/ResetpasswordPage";
import { VerifyPage } from "../views/VerifyPage";
import { DashboardPage } from "../views/DashboardPage";
import { EnrolPage } from "../views/EnrolPage";
import { AssessmentsPage } from "../views/AssessmentsPage";
import { ResultsPage } from "../views/ResultsPage";
import { SupportPage } from "../views/SupportPage";
import { SettingsPage } from "../views/SettingsPage";
import { ModulesPage } from "../views/ModulesPage";
import { SubmitTextPage } from "../views/SubmitTextPage";
import { SubmitPDFPage } from "../views/SubmitPDFPage";
import { SubmitMCQPage } from "../views/SubmitMCQPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/forgot",
    element: <ForgotPage />,
  },
  {
    path: "/reset",
    element: <ResetPage />,
  },
  {
    path: "/verify",
    element: <VerifyPage />,
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
  },
  {
    path: "/enrol",
    element: <EnrolPage />,
  },
  ,
  {
    path: "/assessments",
    element: <AssessmentsPage />,
  },
  {
    path: "/results",
    element: <ResultsPage />,
  },
  {
    path: "/support",
    element: <SupportPage />,
  },
  {
    path: "/profile",
    element: <SettingsPage />,
  },
  {
    path: "/modules",
    element: <ModulesPage />,
  },
  {
    path: "/submit/text",
    element: <SubmitTextPage />,
  },
  {
    path: "/submit/pdf",
    element: <SubmitPDFPage />,
  },
  {
    path: "/submit/mcq",
    element: <SubmitMCQPage />,
  },
  {
    path: "*",
    element: <h1 className="m-4 bold">Opps,Page Not found</h1>,
  },
]);
