import { Request } from './request';

export const LoggedRequest = (navigate) => {
  Request.get('/profile')
    .then((res) => {
      navigate('/dashboard');
    })
    .catch((err) => {
      if (!navigator.onLine) {
        navigate('/login');
      } else {
        if (err.response) {
          if (err.response.status == 401) {
            navigate('/login');
          }
        }
      }
    });
};
