import moment from 'moment';

export const QuizResultComp = ({ data }) => {
  return (
    <div className="col-12 bg-white rounded bg-white shadow-sm mt-4">
      {data && data.length > 0 ? (
        <div className="mx-4 py-4">
          <table>
            <tbody>
              <tr style={{ borderTop: 'solid 1px #ffff' }}>
                <th>Examination</th>
                <th className="d-none d-lg-flex justify-content-center align-items-center">Release Date</th>
                <th>%</th>
                <th className="d-none d-md-flex justify-content-center align-items-center">Outcome</th>
              </tr>
              {data &&
                data.length > 0 &&
                data.map((val, index) => (
                  <tr key={index}>
                    <td>{val.code}</td>
                    <td className="d-none d-lg-flex justify-content-center align-items-center">{moment(val.releaseDate).format('D MMM Y HH:mm:ss')}</td>
                    <td>{val.grade}</td>
                    <td className="d-none d-md-flex justify-content-center align-items-center">
                      {parseInt(val.grade) >= 40 && parseInt(val.grade) < 50 && <span className="bg-warning text-white rounded p-2">Supplementary</span>}
                      {parseInt(val.grade) >= 50 && parseInt(val.grade) < 75 && <span className="bg-success text-white rounded p-2">Pass</span>}
                      {parseInt(val.grade) >= 75 && <span className="bg-info text-white rounded p-2">Distinction</span>}
                      {parseInt(val.grade) < 40 && <span className="bg-danger text-white rounded p-2">Failed</span>}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="col-12 bg-white rounded bg-white shadow-sm mt-4">
          <div className="alert alert-warning my-3" role="alert">
            <h2 className="bold">No Results</h2>
          </div>
        </div>
      )}
    </div>
  );
};
