import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SchoolRequest } from '../requests/moodle/schoolsRequest';
import { RegisterRequest } from '../requests/moodle/registerRequest';
import { RequestLoader } from '../components/common/requestLoader';
import { useNavigate } from 'react-router-dom';

export const RegisterPage = () => {
  window.document.title = 'Register';

  const [error, setError] = useState();
  const [schools, setSchool] = useState();
  const [show, setShow] = useState();
  const navigate = useNavigate();

  const Register = () => {
    const studentNo = document.getElementById('studentNo').value;
    const idNumber = document.getElementById('idNumber').value;
    const name = document.getElementById('name').value;
    const surname = document.getElementById('surname').value;
    const email = document.getElementById('email').value;
    const phone = document.getElementById('phone').value;
    const schoolId = document.getElementById('schoolId').value;
    const accessCode = document.getElementById('accessCode').value;
    const password = document.getElementById('password').value;
    const password_confirm = document.getElementById('password_confirm').value;

    const data = {
      studentNo: studentNo,
      idNumber: idNumber,
      name: name,
      surname: surname,
      email: email,
      cellNumber: phone,
      schoolId: schoolId,
      accessCode: accessCode,
      password: password,
      password_confirmation: password_confirm,
    };

    setShow((prev) => true);
    RegisterRequest(data, setError, setShow, navigate);
  };

  useEffect(() => {
    SchoolRequest(setSchool);
  }, []);

  return (
    <div className="container-fluid signupContainer">
      {show && <RequestLoader />}
      <div className="col-md-10 mx-auto col-12">
        <div className="row">
          <div className="mx-auto col-11 col-md-7 col-lg-5 bg-white signupRound mt-md-5">
            <div className="form-group mb-2 mx-2 mx-md-3">
              <h1 className="bold mb-md-4 mt-4">Register</h1>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">Student Number</label>
              <input type="text" id="studentNo" placeholder="Student Number" className="form-control"></input>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">ID Number</label>
              <input type="text" id="idNumber" placeholder="ID Number" className="form-control"></input>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">Name</label>
              <input type="text" id="name" placeholder="Name" className="form-control"></input>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">Surname</label>
              <input type="text" id="surname" placeholder="Surname" className="form-control"></input>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">Email address</label>
              <input type="text" id="email" placeholder="example@email.com" className="form-control"></input>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">Phone</label>
              <input type="text" id="phone" placeholder="Phone number" className="form-control"></input>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">School</label>
              <select className="form-control" id="schoolId">
                {schools &&
                  schools.map((val, index) => (
                    <option key={index} value={val.id}>
                      {val.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="accessCode">Access Code</label>
              <input type="text" id="accessCode" placeholder="Access Code" className="form-control"></input>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">Password</label>
              <input type="password" id="password" placeholder="********" className="form-control"></input>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">Confirm Password</label>
              <input type="password" id="password_confirm" placeholder="********" className="form-control"></input>
            </div>

            <div className=" mx-md-3 mx-2 mt-4">
              <button style={{ background: '#0A4F92' }} className="form-control btn text-white " onClick={() => Register()}>
                Register
              </button>

              {error && (
                <div className="alert alert-danger my-3" role="alert">
                  {error}
                </div>
              )}

              <p className="text-center my-2  mx-md-3 mx-2 mb-4">
                Already registered? <Link to="/login">Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
