import { Request } from './request';

export const AssessmentsRequest = (navigate, setAssessments, indexDb) => {
  Request.get('/exams')
    .then((res) => {
      setAssessments((prev) => res.data);
      indexDb.getByID('assessments').then((data) => {
        if (data) {
          indexDb.deleteRecord('assessments').then((err) => {
            indexDb.add({ id: 'assessments', assessments: res.data });
          });
        } else {
          indexDb.add({ id: 'assessments', assessments: res.data });
        }
      });
    })
    .catch((err) => {
      if (!navigator.onLine) {
        indexDb.getByID('assessments').then((data) => {
          if (data) {
            setAssessments((prev) => data.assessments);
          } else {
            setAssessments((prev) => []);
          }
        });
      } else {
        if (err.response) {
          if (err.response.status == 401) {
            navigate('/login');
          }
        }
        setAssessments((prev) => []);
      }
    });
};
