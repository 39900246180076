import { Link } from 'react-router-dom';
import moment from 'moment';
import { useIndexedDB } from 'react-indexed-db-hook';
import { DownloadPaper } from './downloadPaper';
import { QuizPaper } from './startExam';
import { MCQStartBtn } from './mcqStartBtn';
import { OpenEndedStartBtn } from './openEndedStartBtn';

export const MyExams = ({ setCode, upcomming, inProgress, setAssign }) => {
  let exams = inProgress.concat(upcomming);

  const { getByID } = useIndexedDB('dashboard');

  return (
    <div className="col-12 mt-4">
      <p className="bold">My Exams</p>
      <div className="col-12 bg-white rounded bg-white shadow-sm">
        <div className="mx-4 py-4">
          <table>
            <tbody>
              <tr style={{ borderTop: 'solid 1px #ffff' }}>
                <th>Module</th>
                <th className="d-none d-lg-flex justify-content-center align-items-center">Duration</th>
                <th>Start</th>
                <th>Close</th>
                <th className="d-none d-lg-flex justify-content-center align-items-center">Type</th>
                <th>Status</th>
              </tr>
              {inProgress &&
                inProgress.length > 0 &&
                inProgress.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td>{val.type == 'MCQ' ? <MCQStartBtn completed={val.completed} timer={val.timer} id={val.id} setCode={setCode} code={val.code} setAssign={setAssign} /> : <OpenEndedStartBtn completed={val.completed} timer={val.timer} id={val.id} setCode={setCode} code={val.code} setAssign={setAssign} />}</td>
                      <td className="d-none d-lg-flex justify-content-center align-items-center">{Diff(moment(val.start), moment(val.close))}</td>
                      <td>{moment(val.start).format('DD MMM HH:mm:ss')}</td>
                      <td>{moment(val.close).format('DD MMM HH:mm:ss')}</td>
                      <td className="d-none d-lg-flex justify-content-center align-items-center">{val.type == 'MCQ' ? <span className="bg-success text-white rounded p-2">{val.type}</span> : <span className="bg-warning text-white rounded p-2">Open-Ended</span>}</td>
                      <td>{val.type == 'MCQ' ? <QuizPaper completed={val.completed} timer={5000} code={val.code} id={val.id} getByID={getByID} setCode={setCode} setAssign={setAssign} /> : <DownloadPaper completed={val.completed} timer={5000} id={val.id} getByID={getByID} />}</td>
                    </tr>
                  );
                })}
              {upcomming &&
                upcomming.length > 0 &&
                upcomming.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td>{val.code}</td>
                      <td className="d-none d-lg-flex justify-content-center align-items-center">{Diff(moment(val.start), moment(val.close))}</td>
                      <td>{moment(val.start).format('DD MMM HH:mm:ss')}</td>
                      <td>{moment(val.close).format('DD MMM HH:mm:ss')}</td>
                      <td className="d-none d-lg-flex justify-content-center align-items-center">{val.type == 'MCQ' ? <span className="bg-success text-white rounded p-2">{val.type}</span> : <span className="bg-warning text-white rounded p-2">Open-Ended</span>}</td>
                      <td>
                        <span className="bg-info text-white rounded p-2">Upcoming</span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      {exams.length > 10 && (
        <div className="row mt-2">
          <div className="col d-flex justify-content-end">
            <button className="btn btn-primary">Show all</button>
          </div>
        </div>
      )}
    </div>
  );
};

export const Diff = (startTime, end) => {
  var duration = moment.duration(end.diff(startTime));
  var hours = duration.asHours();
  var days = duration.asDays();
  var minutes = duration.asMinutes();

  if (hours > 24) {
    if (parseInt(days) < 0) {
      return -1 * parseInt(days) + ' days';
    } else {
      return parseInt(days) + ' days';
    }
  }

  if (hours <= 24 && hours >= 1) {
    if (parseInt(hours) < 0) {
      return -1 * parseInt(hours) + ' hours';
    } else {
      return parseInt(hours) + ' hours';
    }
  } else {
    if (parseInt(minutes) < 0) {
      return -1 * parseInt(minutes) + ' min';
    } else {
      return parseInt(minutes) + ' min';
    }
  }
};
