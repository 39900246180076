import { FaCheckCircle } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';
import { BiSolidErrorCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

export const ExamSubmitted = () => {
  const navigate = useNavigate();
  return (
    <div className="modal fade" id="examSubmitted" tabIndex={-1} aria-labelledby="examSubmitted" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row d-flex justify-content-end">
              <div className="col-1">
                <MdCancel style={{ pointer: 'cursor' }} data-bs-dismiss="modal" aria-label="Close" size={25} className="text-danger" />
              </div>
            </div>

            <div className="row d-flex justify-contnt-center">
              <FaCheckCircle className="text-success" size={60} />
            </div>

            <h1 className="text-center text-success">Thank you</h1>
            <p className="text-center">Examination has been successfully submitted.</p>
            <button onClick={() => navigate('/dashboard')} type="button" className="btn btn-success form-control" data-bs-dismiss="modal">
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ExamFailed = ({ error }) => {
  return (
    <div className="modal fade" id="examFailed" tabIndex={-1} aria-labelledby="examFailed" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row d-flex justify-content-end">
              <div className="col-1">
                <MdCancel style={{ pointer: 'cursor' }} data-bs-dismiss="modal" aria-label="Close" size={25} className="text-danger" />
              </div>
            </div>

            <div className="row d-flex justify-contnt-center">
              <BiSolidErrorCircle className="text-danger" size={60} />
            </div>

            <h1 className="text-center text-danger">Failed</h1>
            <p className="text-center">
              Failed to submit examination reason : <span className="text-danger">{error}</span>
            </p>
            <button type="button" className="btn btn-danger form-control" data-bs-dismiss="modal">
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
