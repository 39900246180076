import { Request } from './request';
import moment from 'moment';
const referrer = document.referrer;
export const DashboardRequest = (navigate, setDash, setShow, indexDb) => {
  const referrer = document.referrer;
  Request.get('/dashboard')
    .then((res) => {
      setShow((prev) => false);
      setDash((prev) => res.data);
      //Cache dashboard
      indexDb.getByID('dashboard').then((data) => {
        if (data) {
          indexDb.deleteRecord('dashboard').then((err) => {
            indexDb.add({ id: 'dashboard', dashboard: res.data });
          });
        } else {
          indexDb.add({ id: 'dashboard', dashboard: res.data });
        }
      });

      //Cache question papers
      const inProgress = res.data.inProgress;
      for (let i = 0; i < inProgress.length; i++) {
        if (inProgress[i].type == 'Open Handed' && inProgress[i].completed == '') {
          PDFQuestions(inProgress[i].courseid, inProgress[i].id, indexDb);
        }
        if (inProgress[i].type == 'MCQ' && inProgress[i].completed == '') {
          StartQuiz(inProgress[i].id, indexDb);
        }
      }
    })
    .catch((err) => {
      if (!navigator.onLine) {
        setShow((prev) => false);
        indexDb.getByID('dashboard').then((data) => {
          if (data) {
            setDash((prev) => data.dashboard);
          } else {
            setDash((prev) => []);
          }
        });
      } else {
        if (err.response) {
          if (err.response.status == 401) {
            navigate('/login');
          }
        }
        setShow((prev) => false);
        setDash((prev) => []);
      }
    });
};

export const PDFQuestions = (courseid, assignid, indexDb) => {
  Request.post('/questions/pdf', {
    courseid: courseid,
    assignid: assignid,
  }).then((res) => {
    const pos = res.data.lastIndexOf('"') + 1;
    const exam = { paper: res.data.substring(pos), type: 'Open Handed', date: moment().toString() };
    indexDb.getByID(assignid).then((data) => {
      if (data) {
        indexDb.deleteRecord(assignid).then((data) => {
          if (data) {
            indexDb.add({ id: assignid, exam });
          }
        });
      } else {
        indexDb.add({ id: assignid, exam });
      }
    });
  });
};

export const StartQuiz = (quizid, indexDb) => {
  Request.post('/quiz/start', {
    quizid: quizid,
  }).then((res) => {
    MCQQuestions(res.data.attemptid, quizid, indexDb);
  });
};

export const MCQQuestions = (attemptid, quizid, indexDb) => {
  Request.post('/questions/mcq', {
    attemptid: attemptid,
  }).then((res) => {
    const exam = { paper: res.data, type: 'MCQ', attemptid: attemptid, date: moment().toString() };
    indexDb.getByID(quizid).then((data) => {
      if (data) {
        indexDb.deleteRecord(quizid).then((err) => {
          indexDb.add({ id: quizid, exam });
        });
      } else {
        indexDb.add({ id: quizid, exam });
      }
    });
  });
};
