import { Request } from './request';
import cryptoJs from 'crypto-js';
import Cookies from 'universal-cookie';

export const LoginRequest = (data, setError, navigate, setShow, deleteRecord) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const cookie = new Cookies();
  Request.post('/login', data)
    .then(async (res) => {
      setShow((prev) => false);
      setError((prev) => '');
      deleteRecord('user');
      deleteRecord('picture');
      const encryptedToken = cryptoJs.AES.encrypt(res.data.auth_user, secretKey).toString();
      var curr = new Date();
      var expire = new Date(curr.getTime() + 30 * 24 * 60 * 60 * 1000);
      cookie.set('_xusr', encryptedToken, { expires: expire, secure: true, sameSite: 'none' });
      window.location.href = '/dashboard';
      //navigate('/dashboard')
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status == 422) {
          setError((prev) => err.response.data.message);
        } else if (err.response.status == 302) {
          window.location.href = '/dashboard';
          //navigate('/dashboard');
        } else {
          setError((prev) => 'Unable login,Contact web master');
        }
      }
      setShow((prev) => false);
    });
};
