import { Link, useNavigate } from 'react-router-dom';
import { BiSolidDashboard } from 'react-icons/bi';
import { FaPlusCircle } from 'react-icons/fa';
import { IoBookmarks } from 'react-icons/io5';
import { FaUserGraduate } from 'react-icons/fa';
import { FaMessage } from 'react-icons/fa6';
import { GiProgression } from 'react-icons/gi';
import { IoMdSettings } from 'react-icons/io';
import { IoLockClosed } from 'react-icons/io5';
import { MdPendingActions } from 'react-icons/md';
import { LogoutRequest } from '../../../requests/moodle/logoutRequest';

export const MobileNav = ({ name }) => {
  const navigate = useNavigate();

  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <a className="navbar-brand text-white" href="#">
          {name}
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="text-white" to="/dashboard">
                <div style={{ height: 50 }} className="row hoverNav">
                  <div className="col-1 align-self-center">
                    <BiSolidDashboard size={15} />
                  </div>
                  <div className="col align-self-center">Dashboard</div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="text-white" to="/enrol">
                <div style={{ height: 50 }} className="row hoverNav">
                  <div className="col-1 align-self-center">
                    <FaPlusCircle size={15} />
                  </div>
                  <div className="col align-self-center">Enrol</div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="text-white" to="/assessments">
                <div style={{ height: 50 }} className="row hoverNav">
                  <div className="col-1 align-self-center">
                    <IoBookmarks size={15} />
                  </div>
                  <div className="col align-self-center">Assessments</div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="text-white" to="/results">
                <div style={{ height: 50 }} className="row hoverNav">
                  <div className="col-1 align-self-center">
                    <GiProgression size={15} />
                  </div>
                  <div className="col align-self-center">Results</div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="text-white" to="/modules">
                <div style={{ height: 50 }} className="row hoverNav">
                  <div className="col-1 align-self-center">
                    <FaUserGraduate size={15} />
                  </div>
                  <div className="col align-self-center">Modules</div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="text-white" to="/support">
                <div style={{ height: 50 }} className="row hoverNav">
                  <div className="col-1 align-self-center">
                    <FaMessage size={15} />
                  </div>
                  <div className="col align-self-center">Support</div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="text-white" to="/pending">
                <div style={{ height: 50 }} className="row hoverNav">
                  <div className="col-1 align-self-center">
                    <MdPendingActions size={15} />
                  </div>
                  <div className="col align-self-center">Pending</div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="text-white" to="/profile">
                <div style={{ height: 50 }} className="row hoverNav">
                  <div className="col-1 align-self-center">
                    <IoMdSettings size={15} />
                  </div>
                  <div className="col align-self-center">Settings</div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="text-white" onClick={() => LogoutRequest(navigate)}>
                <div style={{ height: 50 }} className="row hoverNav">
                  <div className="col-1 align-self-center">
                    <IoLockClosed size={15} />
                  </div>
                  <div className="col align-self-center">Logout</div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
