import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export const MCQStartBtn = ({ code, id, setCode, setAssign, timer, completed }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setShow((prev) => true);
      }, timer);
    } else {
      setShow((prev) => true);
    }
  }, []);

  return (
    <>
      {completed == 'Submitted' ? (
        <>{code}</>
      ) : show ? (
        <Link
          onClick={() => {
            setCode((prev) => code);
            setAssign((prev) => id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#mcqModal"
          className="text-link"
        >
          {code}
        </Link>
      ) : (
        <>{code}</>
      )}
    </>
  );
};
