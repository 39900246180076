import { Request } from './request';

export const UpdateCellRequest = (data, setError, setSuccess, setShow) => {
  Request.post('/profile/cell/update', data)
    .then((res) => {
      setShow((prev) => false);
      setError((prev) => '');
      setSuccess((prev) => 'Cell number updated');
    })
    .catch((err) => {
      if (err.response.status == 422) {
        setError((prev) => err.response.data.message);
        window.scroll(0, document.body.scrollHeight);
      } else {
        setError((prev) => 'Unable to update profile,Contact web master');
        window.scroll(0, document.body.scrollHeight);
      }
      setShow((prev) => false);
      setSuccess((prev) => '');
    });
};

export const UpdatePictureRequest = (data, setError, setSuccess, setShow) => {
  Request.post('/profile/picture/update', data)
    .then((res) => {
      setShow((prev) => false);
      setError((prev) => '');
      setSuccess((prev) => 'Profile picture updated');
    })
    .catch((err) => {
      if (err.response.status == 422) {
        setError((prev) => err.response.data.message);
      } else {
        setError((prev) => 'Unable to update profile,Contact web master');
      }
      setShow((prev) => false);
      setSuccess((prev) => '');
    });
};
