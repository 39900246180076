import { Request } from "./request";

export const MyModulesRequest = (navigate, setModules, indexDb) => {
  Request.get("/mymodules")
    .then((res) => {
      setModules((prev) => res.data);
      indexDb.getByID("mymodules").then((data) => {
        if (data) {
          indexDb.deleteRecord("mymodules").then((err) => {
            indexDb.add({ id: "mymodules", mymodules: res.data });
          });
        } else {
          indexDb.add({ id: "mymodules", mymodules: res.data });
        }
      });
    })
    .catch((err) => {
      if (!navigator.onLine) {
        indexDb.getByID("mymodules").then((data) => {
          if (data) {
            setModules((prev) => data.mymodules);
          } else {
            setModules((prev) => []);
          }
        });
      } else {
        if (err.response.status == 401) {
          navigate("/login");
        }
        setModules((prev) => []);
      }
    });
};
