import { Request } from "./request";

export const ModulesRequest = (navigate, setModules, indexDb) => {
  Request.get("/allmodules")
    .then((res) => {
      setModules((prev) => res.data);
      indexDb.getByID("modules").then((data) => {
        if (data) {
          indexDb.deleteRecord("modules").then((err) => {
            indexDb.add({ id: "modules", modules: res.data });
          });
        } else {
          indexDb.add({ id: "modules", modules: res.data });
        }
      });
    })
    .catch((err) => {
      if (!navigator.onLine) {
        indexDb.getByID("modules").then((data) => {
          if (data) {
            setModules((prev) => data.modules);
          } else {
            setModules((prev) => []);
          }
        });
      } else {
        if (err.response) {
          if (err.response.status == 401) {
            navigate("/login");
          }
        }
        setModules((prev) => []);
      }
    });
};
