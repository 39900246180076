import { Routes, Route } from 'react-router-dom';

import { LoginPage } from '../views/LoginPage';
import { RegisterPage } from '../views/RegisterPage';
import { ForgotPage } from '../views/ForgotPage';
import { ResetPage } from '../views/ResetpasswordPage';
import { ProtectedRoutes } from './protectedRoutes';
import { VerifyPage } from '../views/VerifyPage';
import { DashboardPage } from '../views/DashboardPage';
import { EnrolPage } from '../views/EnrolPage';
import { AssessmentsPage } from '../views/AssessmentsPage';
import { ResultsPage } from '../views/ResultsPage';
import { SupportPage } from '../views/SupportPage';
import { SettingsPage } from '../views/SettingsPage';
import { ModulesPage } from '../views/ModulesPage';
import { SubmitTextPage } from '../views/SubmitTextPage';
import { SubmitPDFPage } from '../views/SubmitPDFPage';
import { SubmitMCQPage } from '../views/SubmitMCQPage';
import { PendingPage } from '../views/PendingPage';
import { RegisteredPage } from '../views/Registered';
import { IndexPage } from '../views/Index';

export const App = () => {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/forgot" element={<ForgotPage />} />
      <Route path="/reset" element={<ResetPage />} />
      <Route path="/registered" element={<RegisteredPage />} />

      <Route element={<ProtectedRoutes />}>
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/dashboard/:id" element={<DashboardPage />} />
        <Route path="/verify" element={<VerifyPage />} />
        <Route path="/enrol" element={<EnrolPage />} />
        <Route path="/assessments" element={<AssessmentsPage />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/profile" element={<SettingsPage />} />
        <Route path="/modules" element={<ModulesPage />} />
        <Route path="/submit/text" element={<SubmitTextPage />} />
        <Route path="/submit/pdf" element={<SubmitPDFPage />} />
        <Route path="/submit/mcq" element={<SubmitMCQPage />} />
        <Route path="/pending" element={<PendingPage />} />
      </Route>

      <Route path="*" element={<h1 className="bold m-1">Opps !!! Page Not Found</h1>} />
    </Routes>
  );
};
