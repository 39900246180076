import { InfinitySpin, RotatingSquare, BallTriangle } from 'react-loader-spinner';
import { LoggedRequest } from '../requests/moodle/isLogged';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const IndexPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      LoggedRequest(navigate);
    }, 2000);
  }, []);

  return (
    <div style={{ height: '100vh' }} className="container-fluid bg-dark d-flex align-items-center justify-content-center">
      <div className="d-none d-md-block">
        <BallTriangle width="80" color="#0A4F92" />
      </div>
      <div className="d-block d-md-none">
        <BallTriangle width="50" color="#0A4F92" />
      </div>
    </div>
  );
};
