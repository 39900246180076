import { Link, useNavigate } from 'react-router-dom';
import { DesktopNav } from '../components/moodle/common/desktopNav';
import { Header } from '../components/moodle/common/commonHeader';
import { RightNav } from '../components/moodle/common/rightNav';
import { useEffect, useState } from 'react';
import { RequestLoader } from '../components/common/requestLoader';
import { useIndexedDB } from 'react-indexed-db-hook';
import { PendingComp } from '../components/moodle/pending/pending';
import { ButtonsModal } from '../components/common/modalBtn';

export const PendingPage = () => {
  window.document.title = 'Pending Submissions';

  const [exams, setExams] = useState();
  const [show, setShow] = useState();

  const { getAll } = useIndexedDB('exams');

  useEffect(() => {
    InitPending();
  }, []);

  const InitPending = () => {
    getAll().then((data) => {
      if (data) {
        const exams = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].submit == true) {
            exams.push(data[i]);
          }
        }
        setExams((prev) => exams);
      }
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <ButtonsModal />
        {show && <RequestLoader />}

        <div className="col-2 d-none d-lg-block">
          <DesktopNav />
        </div>

        <div className="col-lg-10 col-12 mb-4">
          <Header />

          <div className="row">
            <div className="col-lg-9 col-12">
              <p className="bold mt-2">Pending Synchronization</p>
              <div className="col">
                {exams && exams.length > 0 ? (
                  <PendingComp exams={exams} setExams={setExams} setShow={setShow} />
                ) : (
                  <div className="col-12 bg-white rounded bg-white shadow-sm mt-4">
                    <div className="alert alert-warning my-3" role="alert">
                      <h2 className="bold">No Results</h2>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-3 d-none d-lg-block">
              <RightNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
