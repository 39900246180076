import { Link, useNavigate } from 'react-router-dom';
import { DesktopNav } from '../components/moodle/common/desktopNav';
import { Header } from '../components/moodle/common/commonHeader';
import { RightNav } from '../components/moodle/common/rightNav';
import { AssessmentComp } from '../components/moodle/assessments/assessments';
import { AssessmentsRequest } from '../requests/moodle/assessmentsRequest';
import { useEffect, useState } from 'react';
import { RequestLoader } from '../components/common/requestLoader';
import { useIndexedDB } from 'react-indexed-db-hook';

export const AssessmentsPage = () => {
  window.document.title = 'Assessments';

  const [assessments, setAssesments] = useState();
  const navigate = useNavigate();
  const { add, deleteRecord, getByID } = useIndexedDB('assessments');

  useEffect(() => {
    AssessmentsRequest(navigate, setAssesments, { add, deleteRecord, getByID });
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        {!assessments && <RequestLoader />}

        <div className="col-2 d-none d-lg-block">
          <DesktopNav />
        </div>

        <div className="col-lg-10 col-12 mb-4">
          <Header />

          <div className="row">
            <div className="col-lg-9 col-12">
              <p className="bold mt-2">Assessments</p>
              <div className="col">
                {assessments && assessments.inProgress && assessments.upcoming && assessments.pastExams ? (
                  <AssessmentComp inProgress={assessments.inProgress} upcoming={assessments.upcoming} past={assessments.pastExams} />
                ) : (
                  <div className="col-12 bg-white rounded bg-white shadow-sm mt-4">
                    <div className="alert alert-warning my-3" role="alert">
                      <h2 className="bold">No Results</h2>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-3 d-none d-lg-block dash">
              <RightNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
