export const TypeMCQ = ({ question, id, answer }) => {
  return (
    <div className="row">
      <p>
        {id}. {question.question}
      </p>
      {question.answers &&
        question.answers.length > 0 &&
        question.answers.map((val, index) => {
          if (parseInt(index) == parseInt(answer)) {
            return (
              <div key={index} className="input-group mb-3">
                <label htmlFor="answer">
                  <input type="radio" defaultChecked name="mcq" value={index} className="form-check-input me-2" />
                  {val.answer}
                </label>
              </div>
            );
          } else {
            return (
              <div key={index} className="input-group mb-3">
                <label htmlFor="answer">
                  <input type="radio" name="mcq" value={index} className="form-check-input me-2" />
                  {val.answer}
                </label>
              </div>
            );
          }
        })}
    </div>
  );
};
