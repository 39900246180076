import { Link, useNavigate } from 'react-router-dom';
import { DesktopNav } from '../components/moodle/common/desktopNav';
import { Header } from '../components/moodle/common/commonHeader';
import { RightNav } from '../components/moodle/common/rightNav';
import { ModulesRequest } from '../requests/moodle/modulesRequest';
import { useEffect, useState } from 'react';
import { RequestLoader } from '../components/common/requestLoader';
import { EnrolRequest } from '../requests/moodle/enrolRequest';
import { useIndexedDB } from 'react-indexed-db-hook';

export const EnrolPage = () => {
  window.document.title = 'Enrol';

  const navigate = useNavigate();
  const [modules, setModules] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [show, setShow] = useState();
  const [courseName, setCourse] = useState('');
  const { add, deleteRecord, getByID } = useIndexedDB('modules');

  const Enrol = () => {
    const id = document.getElementById('id').value;
    setShow((prev) => true);
    const data = { id: id, courseName: courseName };
    EnrolRequest(data, navigate, setError, setSuccess, setShow);
  };

  const ChangeCourse = (e) => {
    const selectElement = e.target;
    const selectedIndex = selectElement.selectedIndex;
    const selectedOption = selectElement.options[selectedIndex];
    const selectedText = selectedOption.textContent;
    setCourse((prev) => selectedText);
  };

  useEffect(() => {
    ModulesRequest(navigate, setModules, { add, deleteRecord, getByID });
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        {!modules && <RequestLoader />}
        {show && <RequestLoader />}

        <div className="col-2 d-none d-lg-block">
          <DesktopNav />
        </div>

        <div className="col-lg-10 col-12 mb-4">
          <Header />

          <div className="row">
            <div className="col-lg-9 col-12">
              <p className="bold mt-2">Enrolment</p>

              <div className="col">
                <select onChange={(e) => ChangeCourse(e)} id="id" className="form-control">
                  <option value="">--Select Module--</option>
                  {modules &&
                    modules.length > 0 &&
                    modules.map((val, index) => (
                      <option data-course={val.code} key={index} value={val.id}>
                        {val.code}
                      </option>
                    ))}
                </select>

                <div>
                  <button style={{ background: 'rgba(0,0,0,.8)' }} className="btn text-white my-2" onClick={() => Enrol()}>
                    Enrol
                  </button>
                </div>

                {error && (
                  <div className="alert alert-danger my-3" role="alert">
                    {error}
                  </div>
                )}

                {success && (
                  <div className="alert alert-success my-3" role="alert">
                    {success}
                  </div>
                )}
              </div>
            </div>

            <div className="col-3 d-none d-lg-block">
              <RightNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
