import { FaUserGraduate } from 'react-icons/fa';

export const ModulesComp = ({ modules }) => {
  return (
    <div className="row">
      <div className="col mx-1">
        <div className="row gx-2">
          {modules &&
            modules.length > 0 &&
            modules.map((val, index) => (
              <div key={index} className="col-12 col-sm-6 col-md-3 p-3 ">
                <div className="row p-3 shadow-sm rounded bg-white d-flex align-items-center justify-content-center">
                  <center>
                    <FaUserGraduate size={40} className="text-center" />
                  </center>
                  <center>
                    <p className="bold m-0">{val.code}</p>
                  </center>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
