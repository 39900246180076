import { Link, useNavigate } from 'react-router-dom';
import { DesktopNav } from '../components/moodle/common/desktopNav';
import { Header } from '../components/moodle/common/commonHeader';
import { MyExams } from '../components/moodle/dashboard/myExams';
import { RightNav } from '../components/moodle/common/rightNav';
import { History } from '../components/moodle/dashboard/history';
import { McqPopup } from '../components/moodle/dashboard/mcqPopup';
import { OpenHandedModal } from '../components/moodle/dashboard/openHandedModal';
import { useEffect, useState } from 'react';
import { DashboardRequest } from '../requests/moodle/dashboardRequest';
import { RequestLoader } from '../components/common/requestLoader';
import { useIndexedDB } from 'react-indexed-db-hook';
import { Indicators } from '../components/moodle/common/indicators';
import { ButtonsModal } from '../components/common/modalBtn';

export const DashboardPage = () => {
  window.document.title = 'Dashboard';

  const [code, setCode] = useState();
  const [assignid, setAssign] = useState();
  const [show, setShow] = useState();
  const [dashboard, setDash] = useState();

  const { add, deleteRecord, getByID } = useIndexedDB('dashboard');
  const { getByID: getUser } = useIndexedDB('user');

  const navigate = useNavigate();

  useEffect(() => {
    setShow((prev) => true);
    DashboardRequest(navigate, setDash, setShow, {
      add,
      deleteRecord,
      getByID,
    });
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <ButtonsModal />
        {show && <RequestLoader />}
        <McqPopup code={code} assignid={assignid} />
        <OpenHandedModal code={code} assignid={assignid} />
        <div className="d-none d-lg-block col-lg-2">
          <DesktopNav />
        </div>
        <div className="col-12 col-lg-10 mb-4 dash">
          <Header />

          <div className="row">
            <div className="col-12 col-lg-9">
              {dashboard && <Indicators submissions={dashboard.submissions} modules={dashboard.modules} past={dashboard.pastExams} />}
              {(dashboard && dashboard.inProgress && dashboard.inProgress.length > 0) || (dashboard && dashboard.upcoming && dashboard.upcoming.length > 0) ? (
                <MyExams inProgress={dashboard.inProgress} upcomming={dashboard.upcoming} setCode={setCode} setAssign={setAssign} />
              ) : (
                <>
                  <p className="bold mt-4">My Exams</p>
                  <div className="col-12 bg-white rounded bg-white shadow-sm mt-4">
                    <div className="alert alert-warning my-3" role="alert">
                      <h2 className="bold">No Results</h2>
                    </div>
                  </div>
                </>
              )}
              {dashboard && dashboard.pastExams && dashboard.pastExams.length > 0 ? (
                <History history={dashboard.pastExams} />
              ) : (
                <>
                  <p className="bold mt-2">History</p>
                  <div className="col-12 bg-white rounded bg-white shadow-sm mt-4">
                    <div className="alert alert-warning my-3" role="alert">
                      <h2 className="bold">No Results</h2>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="col-3 d-none d-lg-block">
              <RightNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
