import { Link } from 'react-router-dom';
import { useState } from 'react';
import { ForgotRequest } from '../requests/moodle/forgotRequest';
import { useNavigate } from 'react-router-dom';
import { RequestLoader } from '../components/common/requestLoader';

export const ForgotPage = () => {
  window.document.title = 'Forgot Password';

  const [error, setError] = useState();
  const [show, setShow] = useState();

  const navigate = useNavigate();

  const Forgot = () => {
    const email = document.getElementById('email').value;
    const data = { email: email };
    setShow((prev) => true);
    ForgotRequest(data, setError, navigate, setShow);
  };

  return (
    <div className="container-fluid signupContainer d-flex d-md-flex justify-content-center align-items-center">
      {show && <RequestLoader />}
      <div className="col-md-10 mx-auto col-12">
        <div className="row">
          <div className="mx-auto col-11 col-md-7 col-lg-5 bg-white signupRound">
            <div className="form-group mb-2 mx-2 mx-md-3">
              <h1 className="bold mb-md-4 mt-4">Forgot Password</h1>
            </div>

            <div className="form-group mx-2 mx-md-3">
              <p className="mb-md-4 mt-4"> Enter your email below to reset password</p>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">Email address</label>
              <input type="text" id="email" placeholder="example@email.com" className="form-control"></input>
            </div>

            <div className="mb-5 mx-md-3 mt-3 mx-2">
              <button style={{ background: '#0A4F92' }} className="form-control btn text-white " onClick={() => Forgot()}>
                Submit
              </button>

              {error && (
                <div className="alert alert-danger my-3" role="alert">
                  {error}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
