import { useNavigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';

export const RegisteredPage = () => {
  window.document.title = 'Account Registered Successfully';

  const navigate = useNavigate();

  return (
    <div className="container-fluid signupContainer d-flex d-md-flex justify-content-center align-items-center">
      <div className="col-md-10 mx-auto col-12">
        <div className="row">
          <div className="mx-auto col-11 col-md-7 col-lg-5 bg-white signupRound mt-4 py-4">
            <div className="form-group mb-2 mx-2 mx-md-3">
              <div className="d-flex justify-content-center mt-3">
                <FaCheckCircle className="text-primary" size={60} />
              </div>
              <h1 className="bold mb-md-4 mt-4 text-center">Account Created</h1>
              <p>Your account has been created, navigate to the login page</p>
            </div>

            <div className="mb-3 mx-md-3 mt-3 mx-2">
              <button onClick={() => navigate('/login')} style={{ background: '#0A4F92' }} className="form-control btn text-white ">
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
