import { Link, useNavigate } from 'react-router-dom';
import { LoginRequest } from '../requests/moodle/loginRequest';
import { useEffect, useState } from 'react';
import { RequestLoader } from '../components/common/requestLoader';
import { LoggedRequest } from '../requests/moodle/isLogged';
import { useIndexedDB } from 'react-indexed-db-hook';
import { SanctumRequest } from '../requests/moodle/sanctumRequest';
import { LoggedOnLoginRequest } from '../requests/moodle/isLoggedOnLogin';

export const LoginPage = () => {
  window.document.title = 'Login';

  const [error, setError] = useState();
  const [show, setShow] = useState();
  const navigate = useNavigate();
  const { deleteRecord } = useIndexedDB('user');

  const Login = () => {
    // SanctumRequest();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const data = { email: email, password: password, remember: true };
    setShow((prev) => true);
    LoginRequest(data, setError, navigate, setShow, deleteRecord);
  };

  useEffect(() => {
    LoggedOnLoginRequest(navigate);
    if (!navigator.onLine) {
      navigate('/dashboard');
    }
  }, []);

  return (
    <div className="container-fluid signupContainer d-flex d-md-flex justify-content-center align-items-center">
      {show && <RequestLoader />}
      <div className="col-md-10 mx-auto col-12 justify-content-center align-items-center">
        <div className="row">
          <div className="mx-auto col-11 col-md-7 col-lg-5 bg-white signupRound">
            <div className="form-group mb-2 mx-2 mx-md-3">
              <h1 className="bold mb-md-4 mt-4">Login</h1>
            </div>

            <div className="form-group mb-2 mx-md-3 mx-2">
              <label htmlFor="email">Email address</label>
              <input type="text" id="email" placeholder="example@email.com" className="form-control"></input>
            </div>

            <div className="form-group mb-4 mx-md-3 mx-2">
              <label htmlFor="email">Password</label>
              <input type="password" id="password" placeholder="********" className="form-control"></input>
            </div>

            <div className="d-flex justify-content-end mx-md-3">
              <p className="align-self-end">
                <Link to="/forgot">Forgot password?</Link>
              </p>
            </div>

            <div className=" mx-md-3 mx-2">
              <button style={{ background: '#0A4F92' }} className="form-control btn text-white" onClick={() => Login()}>
                Login
              </button>

              {error && (
                <div className="alert alert-danger my-3" role="alert">
                  {error}
                </div>
              )}

              <p className="text-center my-2  mx-md-3 mx-2 mb-4">
                Don’t have account? <Link to="/register">Register</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
