import React, { useState } from 'react';

export const Calendar = () => {
  const [currentDate] = useState(new Date());

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());

  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

  const days = [];
  let dayCounter = 1;

  // Create an array to hold the names of the days
  const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  for (let i = 0; i < 7; i++) {
    days.push(<th key={weekDays[i]}>{weekDays[i]}</th>);
  }

  const weeks = [];
  for (let week = 0; dayCounter <= daysInMonth; week++) {
    const cells = [];
    for (let day = 0; day < 7; day++) {
      if ((week === 0 && day < firstDayOfMonth) || dayCounter > daysInMonth) {
        cells.push(<td key={`${week}-${day}`} className="empty"></td>);
      } else {
        if (dayCounter === currentDate.getDate()) {
          cells.push(
            <td key={`${week}-${day}`} className="bg-dark text-white rounded">
              {dayCounter}
            </td>,
          );
        } else {
          cells.push(<td key={`${week}-${day}`}>{dayCounter}</td>);
        }

        dayCounter++;
      }
    }
    weeks.push(<tr key={week}>{cells}</tr>);
  }

  return (
    <div className="calendar mx-1">
      <table className="custCalendar">
        <thead>
          <tr>{days}</tr>
        </thead>
        <tbody>{weeks}</tbody>
      </table>
    </div>
  );
};
