import { Link, useNavigate } from 'react-router-dom';
import { BiSolidDashboard } from 'react-icons/bi';
import { FaPlusCircle } from 'react-icons/fa';
import { IoBookmarks } from 'react-icons/io5';
import { FaUserGraduate } from 'react-icons/fa';
import { FaMessage } from 'react-icons/fa6';
import { FaUser } from 'react-icons/fa';
import { GiProgression } from 'react-icons/gi';
import { TbNetwork } from 'react-icons/tb';
import { useEffect, useState } from 'react';
import { UserRequest } from '../../../requests/moodle/userRequest';
import { useIndexedDB } from 'react-indexed-db-hook';
import { MdPendingActions } from 'react-icons/md';

export const DesktopNav = () => {
  const [isOnline, setOnline] = useState(navigator.onLine);
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [picture, setPicture] = useState();
  const { add, getByID, deleteRecord } = useIndexedDB('user');
  const { getAll } = useIndexedDB('exams');
  const [isPending, setPending] = useState(false);

  useEffect(() => {
    UserRequest(navigate, setUser, { add, deleteRecord, getByID }, true);
    getByID('picture').then((data) => {
      if (data) {
        const profile = URL.createObjectURL(data.picture);
        setPicture((prev) => profile);
      }
    });

    setInterval(() => {
      setOnline((prev) => navigator.onLine);
    }, 1000);

    setInterval(() => {
      getAll().then((data) => {
        let count = 0;
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].submit == true) {
              count++;
            }
          }
          if (count > 0) {
            setPending((prev) => true);
          }
        }
      });
    }, 500);
  }, []);

  return (
    <div style={{ background: 'rgba(0,0,0,.8)', minHeight: '100vh', height: '100%' }} className="row text-white">
      <div className="col-12">
        {isOnline ? (
          <div style={{ position: 'absolute', bottom: 10 }}>
            <p>
              <TbNetwork color={'lime'} size={15} /> <span style={{ fontSize: 12 }}>Online</span>
            </p>
          </div>
        ) : (
          <div style={{ position: 'absolute', bottom: 10 }}>
            <p>
              <TbNetwork color={'grey'} size={15} /> <span style={{ fontSize: 12 }}>Offline</span>
            </p>
          </div>
        )}

        <div className="row my-5">
          <div className="col-3 align-self-center">
            {picture && <img src={picture} style={{ borderRadius: 100, height: 40, width: 40 }} />}
            {!picture && <FaUser size={30} />}
          </div>
          <div className="col align-self-center">
            {user && (
              <>
                {user.name.substring(0, 1)} {user.surname}
              </>
            )}
          </div>
        </div>

        <Link className="text-white" to="/dashboard">
          <div style={{ height: 50 }} className="row hoverNav">
            <div className="col-2 align-self-center">
              <BiSolidDashboard size={15} />
            </div>
            <div className="col align-self-center">Dashboard</div>
          </div>
        </Link>

        <Link className="text-white" to="/enrol">
          <div style={{ height: 50 }} className="row hoverNav">
            <div className="col-2 align-self-center">
              <FaPlusCircle size={15} />
            </div>
            <div className="col align-self-center">Enrol</div>
          </div>
        </Link>

        <Link className="text-white" to="/assessments">
          <div style={{ height: 50 }} className="row hoverNav">
            <div className="col-2 align-self-center">
              <IoBookmarks size={15} />
            </div>
            <div className="col align-self-center">Assessments</div>
          </div>
        </Link>

        <Link className="text-white" to="/results">
          <div style={{ height: 50 }} className="row hoverNav">
            <div className="col-2 align-self-center">
              <GiProgression size={15} />
            </div>
            <div className="col align-self-center">Results</div>
          </div>
        </Link>

        <Link className="text-white" to="/modules">
          <div style={{ height: 50 }} className="row hoverNav">
            <div className="col-2 align-self-center">
              <FaUserGraduate size={15} />
            </div>
            <div className="col align-self-center">Modules</div>
          </div>
        </Link>

        <Link className="text-white" to="/support">
          <div style={{ height: 50 }} className="row hoverNav">
            <div className="col-2 align-self-center">
              <FaMessage size={15} />
            </div>
            <div className="col align-self-center">Support</div>
          </div>
        </Link>

        <Link className="text-white" to="/pending">
          <div style={{ height: 50 }} className="row hoverNav">
            <div className="col-2 align-self-center">
              <MdPendingActions size={16} />
            </div>
            <div className="col align-self-center d-flex">Pending{isPending && <div style={{ width: '10px', height: '10px', borderRadius: '100%' }} className="bg-danger p-1 mx-2 align-self-center"></div>}</div>
          </div>
        </Link>
      </div>
    </div>
  );
};
