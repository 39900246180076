import { Link, useLocation } from 'react-router-dom';
import { DesktopNav } from '../components/moodle/common/desktopNav';
import { Header } from '../components/moodle/common/commonHeader';
import { RightNav } from '../components/moodle/common/rightNav';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitTextRequest } from '../requests/moodle/submitTextRequest';
import { RequestLoader } from '../components/common/requestLoader';
import { useIndexedDB } from 'react-indexed-db-hook';
import { ExamSaved } from '../components/common/examSaved';
import { ExamFailed } from '../components/common/examSubmitted';
import { ExamSubmitted } from '../components/common/examSubmitted';
import { ButtonsModal } from '../components/common/modalBtn';
import 'react-trumbowyg/dist/trumbowyg.min.css';
import Trumbowyg from 'react-trumbowyg';
import moment from 'moment';

export const SubmitTextPage = () => {
  window.document.title = 'Submit Open Handed Text';

  const [code, setCode] = useState();
  const [assignid, setAssignId] = useState();

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [show, setShow] = useState();
  const [failed, setFailed] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  const { add, deleteRecord, getByID, update } = useIndexedDB('exams');

  const examSaved = document.getElementById('savedClick');
  const examFailed = document.getElementById('failedClick');
  const examSubmitted = document.getElementById('submittedClick');

  useEffect(() => {
    if (location.state) {
      if (location.state.code) {
        setCode((prev) => location.state.code);
        setAssignId((prev) => location.state.assignid);
        prevAnswers(location.state.assignid);
      } else {
        navigate('/dashboard');
      }
    } else {
      navigate('/dashboard');
    }
  }, []);

  const prevAnswers = (id) => {
    getByID(id).then((data) => {
      if (data) {
        if (data.type == 'text') {
          let div = document.getElementById('answers');
          div.innerHTML = data.answers;
        }
      }
    });
  };

  const Submit = async () => {
    setShow((prev) => true);
    let div = document.getElementById('answers');
    let answers = '';
    // Get all child elements of the 'answers' element
    let children = div.children;

    if (children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        let child = children[i];
        answers += child.outerHTML; // Get the HTML representation of the element
      }
    } else {
      answers = div.innerHTML;
    }

    const data = {
      code: code,
      answers: answers,
      assignid: assignid,
      offlineTime: moment().toString(),
    };

    if (answers) {
      if (!navigator.onLine) {
        const confirm = window.confirm('You are about to submit your examination. Click OK to proceed or cancel');
        if (confirm) {
          getByID(assignid).then((data) => {
            if (data) {
              deleteRecord(assignid).then((res) => {
                add({
                  id: assignid,
                  code: code,
                  answers: answers,
                  assignid: assignid,
                  submit: true,
                  type: 'text',
                  offlineTime: moment().toString(),
                });
                examSaved.click();
              });
            } else {
              add({
                id: assignid,
                code: code,
                answers: answers,
                assignid: assignid,
                submit: true,
                type: 'text',
                offlineTime: moment().toString(),
              });
              examSaved.click();
            }
            setShow((prev) => false);
          });
        } else {
          setShow((prev) => false);
        }
      } else {
        const confirm = window.confirm('You are about to submit your examination. Click OK to proceed or cancel');
        if (confirm) {
          await SubmitTextRequest(data, navigate, setError, setSuccess, {}, setShow, { examFailed, examSubmitted }, deleteRecord);
          if (error) {
            setFailed((prev) => error);
          }
        } else {
          setShow((prev) => false);
        }
      }
    } else {
      setShow((prev) => false);
      setFailed((prev) => 'Please fill in your answers');
      examFailed.click();
    }
  };

  const autoSave = () => {
    let div = document.getElementById('answers');
    let answers = '';
    // Get all child elements of the 'answers' element
    let children = div.children;

    if (children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        let child = children[i];
        answers += child.outerHTML; // Get the HTML representation of the element
      }
    } else {
      answers = div.innerHTML;
    }

    getByID(location.state.assignid).then(async (data) => {
      const res = await data;
      if (res) {
        update({
          id: location.state.assignid,
          code: location.state.code,
          answers: answers,
          assignid: location.state.assignid,
          submit: false,
          type: 'text',
          offlineTime: moment().toString(),
        });
      } else {
        add({
          id: location.state.assignid,
          code: location.state.code,
          answers: answers,
          assignid: location.state.assignid,
          submit: false,
          type: 'text',
          offlineTime: moment().toString(),
        });
      }
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <ButtonsModal />

        <ExamSaved />
        <ExamFailed error={failed} />
        <ExamSubmitted />

        {show && <RequestLoader />}

        <div className="col-2 d-none d-lg-block">
          <DesktopNav />
        </div>

        <div className="col-lg-10 col-12 mb-4">
          <Header />

          <div className="row">
            <div className="col-lg-9 col-12">
              {code && <p className="bold mt-2">{code}</p>}
              <div className="col">
                <Trumbowyg id="answers" onChange={() => autoSave()} data={null} />
                <div>
                  <button style={{ background: 'rgba(0,0,0,.8)' }} className="btn text-white my-2" onClick={() => Submit()}>
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <div className="col-3 d-none d-lg-block">
              <RightNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
