import { DesktopNav } from '../components/moodle/common/desktopNav';
import { Header } from '../components/moodle/common/commonHeader';
import { RightNav } from '../components/moodle/common/rightNav';
import { useState } from 'react';
import { SupportRequest } from '../requests/moodle/supportRequest';
import { RequestLoader } from '../components/common/requestLoader';

export const SupportPage = () => {
  window.document.title = 'Support';

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [show, setShow] = useState();

  const Send = () => {
    const subject = document.getElementById('subject').value;
    const message = document.getElementById('message').value;

    const data = {
      subject: subject,
      message: message,
    };

    if (subject && message) {
      setShow((prev) => true);
      SupportRequest(data, setError, setSuccess, setShow);
    } else {
      setError((prev) => 'Enter all required fileds');
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {show && <RequestLoader />}
        <div className="col-2 d-none d-lg-block">
          <DesktopNav />
        </div>

        <div className="col-lg-10 col-12 mb-4">
          <Header />

          <div className="row">
            <div className="col-lg-9 col-12">
              <p className="bold mt-2">Support</p>

              <div className="col">
                <div className="form-group">
                  <label htmlFor="email">Subject</label>

                  <input type="text" id="subject" placeholder="Subject" className="form-control"></input>
                </div>

                <div className="form-group">
                  <label htmlFor="email">Subject</label>

                  <textarea style={{ height: 150 }} type="text" id="message" placeholder="Your message here" className="form-control"></textarea>
                </div>

                <div>
                  <button onClick={() => Send()} style={{ background: 'rgba(0,0,0,.8)' }} className="btn text-white my-2">
                    Send
                  </button>
                </div>

                {error && (
                  <div className="alert alert-danger my-3" role="alert">
                    {error}
                  </div>
                )}

                {success && (
                  <div className="alert alert-success my-3" role="alert">
                    {success}
                  </div>
                )}
              </div>
            </div>

            <div className="col-3 d-none d-lg-block">
              <RightNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
