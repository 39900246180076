import { Request } from './request';

export const ResetRequest = (data, setError, setSuccess, setShow) => {
  Request.post('/reset-password', data)
    .then((res) => {
      setShow((prev) => false);
      setError((prev) => '');
      setSuccess((prev) => 'Password has been recovered');
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status == 422) {
          setError((prev) => err.response.data.message);
          setSuccess((prev) => '');
        } else {
          setError((prev) => 'Unable reset password,Contact web master');
          setSuccess((prev) => '');
        }
      } else {
        setError((prev) => 'Unable reset password,Contact web master');
        setSuccess((prev) => '');
      }
      setShow((prev) => false);
    });
};
