import { Request } from './request';

export const ForgotRequest = (data, setError, navigate, setShow) => {
  Request.post('/forgot-password', data)
    .then((res) => {
      setShow((prev) => false);
      setError((prev) => '');
      navigate('/reset', { state: data });
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.status == 422) {
          if (err.response.data.errors.otp) {
            navigate('/reset', { state: data });
          } else {
            setError((prev) => err.response.data.message);
          }
        }
        if (err.response.status == 302) {
          navigate('/dashboard');
        }
      } else {
        setError((prev) => 'Unable reset password,Contact web master');
      }
      setShow((prev) => false);
    });
};
