import { FaCheckCircle } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
export const ExamSaved = () => {
  const navigate = useNavigate();

  return (
    <div className="modal fade" id="examSaved" tabIndex={-1} aria-labelledby="examSaved" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row d-flex justify-content-end">
              <div className="col-1">
                <MdCancel style={{ pointer: 'cursor' }} data-bs-dismiss="modal" aria-label="Close" size={25} className="text-danger" />
              </div>
            </div>

            <div className="row d-flex justify-contnt-center">
              <FaCheckCircle className="text-success" size={60} />
            </div>

            <h1 className="text-center text-success">Success</h1>
            <p className="text-center">
              Your examination has been saved. <span className="text-danger bold">Please connect to the internet to sync your examination.</span>
            </p>
            <button onClick={() => navigate('/dashboard')} type="button" className="btn btn-success form-control" data-bs-dismiss="modal">
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
