import { Link, useNavigate } from 'react-router-dom';
import { DesktopNav } from '../components/moodle/common/desktopNav';
import { Header } from '../components/moodle/common/commonHeader';
import { RightNav } from '../components/moodle/common/rightNav';
import { ModulesComp } from '../components/moodle/modules/modules';
import { MyModulesRequest } from '../requests/moodle/myModulesRequest';
import { useEffect, useState } from 'react';
import { RequestLoader } from '../components/common/requestLoader';
import { useIndexedDB } from 'react-indexed-db-hook';

export const ModulesPage = () => {
  window.document.title = 'My Modules';

  const navigate = useNavigate();
  const [myModules, setModules] = useState();
  const { add, deleteRecord, getByID } = useIndexedDB('mymodules');

  useEffect(() => {
    MyModulesRequest(navigate, setModules, { add, deleteRecord, getByID });
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        {!myModules && <RequestLoader />}

        <div className="col-2 d-none d-lg-block">
          <DesktopNav />
        </div>

        <div className="col-lg-10 col-12 mb-4">
          <Header />

          <div className="row">
            <div className="col-lg-9 col-12">
              <p className="bold mt-2">My modules</p>
              <div className="col">
                {myModules && myModules.length > 0 ? (
                  <ModulesComp modules={myModules} />
                ) : (
                  <div className="col-12 bg-white rounded bg-white shadow-sm mt-4">
                    <div className="alert alert-warning my-3" role="alert">
                      <h2 className="bold">No Results</h2>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-3 d-none d-lg-block">
              <RightNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
