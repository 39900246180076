import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { Calendar } from '../dashboard/calendar';

export const RightNav = () => {
  const [date, setDate] = useState(moment().format('LL'));

  useEffect(() => {
    setInterval(() => {
      setDate((prev) => moment().format('LL'));
    }, 60000);
  }, []);

  return (
    <>
      <div className="mt-4">
        <p className="bold m-2">Notice board</p>
      </div>
      <div className="col-12 bg-white rounded bg-white shadow-sm py-2 mt-2">
        <p style={{ fontSize: 14 }} className="m-2 bold">
          {date}
        </p>
        <Calendar />
      </div>
      {/* <div className="col-12 bg-white rounded bg-white shadow-sm py-2 mt-3">
        <p className="bold m-2">0 Notifications</p>
      </div> */}
    </>
  );
};
