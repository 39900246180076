import { Outlet, Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

export const ProtectedRoutes = () => {
  let logged = false;
  //const cookie = document.cookie;
  const cookie = new Cookies();

  if (!navigator.onLine) {
    logged = true;
  } else {
    /*   if (cookie.includes('XSRF-TOKEN')) {
      logged = true;
    } */
    if (cookie.get('_xusr')) {
      logged = true;
    }
  }
  return logged ? <Outlet /> : <Navigate to="/" />;
};
