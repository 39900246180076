import { Link, useNavigate } from 'react-router-dom';
import { DesktopNav } from '../components/moodle/common/desktopNav';
import { Header } from '../components/moodle/common/commonHeader';
import { RightNav } from '../components/moodle/common/rightNav';
import { AssignResultsComp } from '../components/moodle/results/assignResults';
import { QuizRequest } from '../requests/moodle/resultsRequest';
import { OpenHandedRequest } from '../requests/moodle/resultsRequest';
import { useEffect, useState } from 'react';
import { QuizResultComp } from '../components/moodle/results/quizResults';
import { RequestLoader } from '../components/common/requestLoader';
import { useIndexedDB } from 'react-indexed-db-hook';

export const ResultsPage = () => {
  window.document.title = 'Results';

  const navigate = useNavigate();

  const [quiz, setQuiz] = useState();
  const [openHanded, setOpenHanded] = useState();
  const [show, setShow] = useState('assign');
  const [showLoad, setLoading] = useState('assign');
  const { add, deleteRecord, getByID } = useIndexedDB('results');

  const onChange = () => {
    const result = document.getElementById('type').value;

    if (result == 'open') {
      setShow((prev) => 'assign');
      AssignResults();
    }
    if (result == 'quiz') {
      setShow((prev) => 'quiz');
      QuizResults();
    }
  };

  const QuizResults = () => {
    setLoading((prev) => true);
    QuizRequest(navigate, setQuiz, setLoading, { add, deleteRecord, getByID });
  };

  const AssignResults = () => {
    setLoading((prev) => true);
    OpenHandedRequest(navigate, setOpenHanded, setLoading, { add, deleteRecord, getByID });
  };

  useEffect(() => {
    setLoading((prev) => true);
    OpenHandedRequest(navigate, setOpenHanded, setLoading, { add, deleteRecord, getByID });
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        {show == 'quiz' && showLoad && <RequestLoader />}
        {show == 'assign' && showLoad && <RequestLoader />}

        <div className="col-2 d-none d-lg-block">
          <DesktopNav />
        </div>

        <div className="col-lg-10 col-12 mb-4">
          <Header />

          <div className="row">
            <div className="col-lg-9 col-12">
              <p className="bold mt-2">Results</p>

              <div className="col">
                <select id="type" onChange={() => onChange()} className="form-select">
                  <option value="open">Open-Ended</option>
                  <option value="quiz">MCQ</option>
                </select>

                {show == 'quiz' && <QuizResultComp data={quiz} />}
                {show == 'assign' && <AssignResultsComp data={openHanded} />}
              </div>
            </div>

            <div className="col-3 d-none d-lg-block">
              <RightNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
