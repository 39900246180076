import { useState } from 'react';
import { useIndexedDB } from 'react-indexed-db-hook';
import { useNavigate } from 'react-router-dom';
import { Request } from '../../../requests/moodle/request';
import { ExamFailed, ExamSubmitted } from '../../common/examSubmitted';

export const PendingComp = ({ exams, setShow, setExams }) => {
  const [error, setError] = useState();
  const { deleteRecord, getAll } = useIndexedDB('exams');
  const { deleteRecord: deleteDash } = useIndexedDB('dashboard');
  const navigate = useNavigate();

  const examFailedClick = document.getElementById('failedClick');
  const examSubmittedClick = document.getElementById('submittedClick');

  const CheckOnline = (exam) => {
    if (navigator.onLine) {
      Submit(exam);
    } else {
      setError((prev) => 'You need internet connection to submit');
      examFailedClick.click();
    }
  };

  const InitPending = () => {
    getAll().then((data) => {
      if (data) {
        setExams((prev) => data);
      }
    });
  };

  const Submit = (exam) => {
    setShow((prev) => true);

    if (exam.type == 'text') {
      const data = {
        code: exam.code,
        answers: exam.answers,
        assignid: exam.assignid,
        offlineTime: exam.offlineTime,
      };

      Request.post('/openhanded', data)
        .then((res) => {
          setError((prev) => '');
          setShow((prev) => false);
          deleteRecord(exam.id);
          deleteDash(exam.id);
          examSubmittedClick.click();
          InitPending();
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 401) {
              navigate('/login');
            }
            if (err.response.status == 422) {
              setError((prev) => err.response.data.message);
              deleteRecord(exam.id);
              deleteDash(exam.id);
              examFailedClick.click();
              InitPending();
            }
          }
          setShow((prev) => false);
        });
    }
    if (exam.type == 'pdf') {
      const data = new FormData();
      data.append('code', exam.code);
      data.append('assingid', exam.assignid);
      data.append('file', exam.file);
      data.append('offlineTime', exam.offlineTime);

      Request.post('/file/openended', data)
        .then((res) => {
          setError((prev) => '');
          setShow((prev) => false);
          deleteRecord(exam.id);
          deleteDash(exam.id);
          examSubmittedClick.click();
          InitPending();
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 401) {
              navigate('/login');
            }
            if (err.response.status == 422) {
              setError((prev) => err.response.data.message);
              deleteRecord(exam.id);
              deleteDash(exam.id);
              examFailedClick.click();
              InitPending();
            }
          }
          setShow((prev) => false);
        });
    }
    if (exam.type == 'mcq') {
      const data = {
        code: exam.code,
        attemptid: exam.attemptid,
        answers: exam.answers,
        offlineTime: exam.offlineTime,
      };

      Request.post('/mcq', data)
        .then((res) => {
          setError((prev) => '');
          setShow((prev) => false);
          deleteRecord(exam.id);
          deleteDash(exam.id);
          examSubmittedClick.click();
          InitPending();
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 401) {
              navigate('/login');
            }
            if (err.response.status == 422) {
              setError((prev) => err.response.data.message);
              deleteRecord(exam.id);
              deleteDash(exam.id);
              examFailedClick.click();
              InitPending();
            }
          }
          setShow((prev) => false);
        });
    }
  };

  return (
    <div className="col-12 bg-white rounded bg-white shadow-sm mt-4">
      <ExamFailed error={error} />
      <ExamSubmitted />
      <div className="mx-4 py-4">
        <table>
          <tbody>
            <tr style={{ borderTop: 'solid 1px #ffff' }}>
              <th>Module</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            {exams &&
              exams.length > 0 &&
              exams.map((val, index) => (
                <tr key={index}>
                  <td>{val.code}</td>
                  <td>
                    <span className="bg-danger p-1 rounded text-white">Pending submission</span>
                  </td>
                  <td>
                    <button onClick={() => CheckOnline(val)} style={{ height: 40 }} className="btn btn-primary text-white">
                      Synchronize
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
