import moment from 'moment';

export const History = ({ history }) => {
  return (
    <div className="col-12 mt-4">
      <p className="bold">History</p>
      <div className="col-12 bg-white rounded bg-white shadow-sm">
        <div className="mx-4 py-4">
          <table>
            <tbody>
              <tr style={{ borderTop: 'solid 1px #ffff' }}>
                <th>Name</th>
                <th>Completion</th>
                <th>Type</th>
                <th className="d-none d-lg-flex justify-content-center align-items-center">Status</th>
              </tr>
              {history &&
                history.length > 0 &&
                history.map((val, index) => (
                  <tr key={index}>
                    <td>{val.name}</td>
                    <td>{moment(val.close).format('DD MMM Y HH:mm:ss')}</td>
                    <td>{val.type == 'MCQ' ? <span className="bg-success text-white rounded p-2">{val.type}</span> : <span className="bg-warning text-white rounded p-2">Open-Ended</span>}</td>
                    <td className="d-none d-lg-flex justify-content-center align-items-center">
                      <span className="bg-danger text-white rounded p-2">Past</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {history.length > 10 && (
        <div className="row mt-2">
          <div className="col d-flex justify-content-end">
            <button className="btn btn-primary">Show all</button>
          </div>
        </div>
      )}
    </div>
  );
};
