import { Link } from 'react-router-dom';
import { DesktopNav } from '../components/moodle/common/desktopNav';
import { Header } from '../components/moodle/common/commonHeader';
import { RightNav } from '../components/moodle/common/rightNav';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaUpload } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { SubmitPDFRequest } from '../requests/moodle/submitPDFRequest';
import { RequestLoader } from '../components/common/requestLoader';
import { useIndexedDB } from 'react-indexed-db-hook';
import { ExamSaved } from '../components/common/examSaved';
import { ExamFailed } from '../components/common/examSubmitted';
import { ExamSubmitted } from '../components/common/examSubmitted';
import { ButtonsModal } from '../components/common/modalBtn';
import moment from 'moment';

export const SubmitPDFPage = () => {
  const [code, setCode] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const [assignid, setAssignId] = useState();

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [show, setShow] = useState();
  const [failed, setFailed] = useState();

  const { add, deleteRecord, getByID, update } = useIndexedDB('exams');

  const examSaved = document.getElementById('savedClick');
  const examFailed = document.getElementById('failedClick');
  const examSubmitted = document.getElementById('submittedClick');

  window.document.title = 'Submit Open Handed PDF';

  useEffect(() => {
    if (location.state) {
      if (location.state.code) {
        setCode((prev) => location.state.code);
        setAssignId((prev) => location.state.assignid);
        prevAnswers(location.state.assignid);
      } else {
        navigate('/dashboard');
      }
    } else {
      navigate('/dashboard');
    }
  }, []);

  const prevAnswers = (id) => {
    getByID(id).then((data) => {
      if (data) {
        if (data.type == 'pdf') {
          document.getElementById('holder').innerHTML = data.file.name;
        }
      }
    });
  };

  const openFile = () => {
    const file = document.getElementById('file');
    file.click();
  };

  const fileChanged = () => {
    const file = document.getElementById('file');
    if (file.files[0]) {
      if (file.files[0].name.includes('.pdf')) {
        getByID(location.state.assignid).then(async (data) => {
          const res = await data;
          if (res) {
            update({
              id: location.state.assignid,
              code: location.state.code,
              file: file.files[0],
              assignid: location.state.assignid,
              submit: false,
              type: 'pdf',
              offlineTime: moment().toString(),
            });
          } else {
            add({
              id: location.state.assignid,
              code: location.state.code,
              file: file.files[0],
              assignid: location.state.assignid,
              submit: false,
              type: 'pdf',
              offlineTime: moment().toString(),
            });
          }
        });
        document.getElementById('holder').innerHTML = file.files[0].name;
      } else {
        setFailed('Only PDF files allowed');
        examFailed.click();
      }
    }
  };

  const Submit = async () => {
    setShow((prev) => true);
    const file = document.getElementById('file');

    if (file.files[0]) {
      const data = new FormData();
      data.append('code', code);
      data.append('assingid', assignid);
      data.append('file', file.files[0]);
      data.append('offlineTime', moment().toString());

      if (!navigator.onLine) {
        const confirm = window.confirm('You are about to submit your examination. Click OK to proceed or cancel');
        if (confirm) {
          getByID(assignid).then((data) => {
            if (data) {
              deleteRecord(assignid).then((res) => {
                add({
                  id: assignid,
                  code: code,
                  file: file.files[0],
                  assignid: assignid,
                  submit: true,
                  type: 'pdf',
                  offlineTime: moment().toString(),
                });
                setShow((prev) => false);
                examSaved.click();
              });
            } else {
              add({
                id: assignid,
                code: code,
                file: file.files[0],
                assignid: assignid,
                submit: true,
                type: 'pdf',
                offlineTime: moment().toString(),
              });
              setShow((prev) => false);
              examSaved.click();
            }
            setShow((prev) => false);
          });
        } else {
          setShow((prev) => false);
        }
      } else {
        const confirm = window.confirm('You are about to submit your examination. Click OK to proceed or cancel');
        if (confirm) {
          await SubmitPDFRequest(data, navigate, setError, setSuccess, {}, setShow, { examFailed, examSubmitted }, deleteRecord);
          if (error) {
            setFailed((prev) => error);
          }
        } else {
          setShow((prev) => false);
        }
      }
    } else {
      setShow((prev) => false);
      setFailed((prev) => 'Please upload pdf file');
      examFailed.click();
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <ButtonsModal />

        <ExamSaved />
        <ExamFailed error={failed} />
        <ExamSubmitted />

        {show && <RequestLoader />}

        <div className="col-2 d-none d-lg-block">
          <DesktopNav />
        </div>

        <div className="col col-lg-10 mb-4">
          <Header />

          <div className="row">
            <div className="col col-lg-9">
              {code && <p className="bold mt-2">{code}</p>}

              <div className="col">
                <div style={{ border: 'dashed 3px #dddd', cursor: 'pointer' }} className="rounded py-5 d-flex align-items-center justify-content-center" onClick={() => openFile()}>
                  <div className="row d-flex align-items-center justify-content-center">
                    <p>
                      <FaUpload size={40} />
                      <span className="mx-2" id="holder">
                        Upload your file here
                      </span>
                    </p>
                    <input type="file" id="file" accept="application/pdf" hidden onChange={fileChanged}></input>
                  </div>
                </div>

                <div>
                  <button onClick={() => Submit()} style={{ background: 'rgba(0,0,0,.8)' }} className="btn text-white my-2">
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <div className="col-3 d-none d-lg-block">
              <RightNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
