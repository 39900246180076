import { Request } from './request';

export const SupportRequest = (data, setError, setSuccess, setShow) => {
  Request.post('/message', data)
    .then((res) => {
      setShow((prev) => false);
      setError((prev) => '');
      setSuccess((prev) => 'Message sent');
    })
    .catch((err) => {
      if (!navigator.onLine) {
        setError((prev) => 'You need connection to send message');
        setShow((prev) => false);
        setSuccess((prev) => '');
      } else {
        if (err.response.status == 422) {
          setError((prev) => err.response.data.message);
        } else {
          setError((prev) => 'Unable to send message,Contact web master');
        }
        setShow((prev) => false);
        setSuccess((prev) => '');
      }
    });
};
